<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">Total Users</v-card-title>
          <v-card-text class="text-h6">{{ all_users }}</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Partnered Users
          </v-card-title>
          <v-card-text class="text-h6">{{ partnered_users }}</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Subscribed Users
          </v-card-title>
          <v-card-text class="text-h6">{{ subscribed_users }}</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            <small>Video Request Users</small>
          </v-card-title>
          <v-card-text class="text-h6">{{ video_request_users }}</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">Free Users</v-card-title>
          <v-card-text class="text-h6">{{ free_users }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-4">
      <users></users>
    </div>
  </div>
</template>

<script>
import users from "../../components/Users";
export default {
  components: {
    users,
  },
  name: "UsersPage",
  data() {
    return {
      all_users: 0,
      partnered_users: 0,
      subscribed_users: 0,
      video_request_users: 0,
      free_users: 0,
    };
  },
};
</script>

<style scoped></style>
